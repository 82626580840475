<script setup>
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {postApplicants,getJobByCode,postJobApply} from "@/lib/api.js";
import AppPage from "@/components/AppPage.vue";
import useVuelidate from "@vuelidate/core";
import VCurrencyInput from "@/components/input/vCurrencyInput/VCurrencyInput.vue";
import useNotify from "@/plugins/notify/index.js";
import {MESSAGE_SUCCESS_CREATED, MESSAGE_SUCCESS_UPDATED} from "@/constants/default-messages.js";
import JobVacancyInformation from "@/components/shared/JobVacancyInformation.vue";
import storeJobCode from "@/stores/applicants.js";
import useAuthStore from "@/stores/auth.js";
import QEditButtonGroup from "@/components/button/QEditButtonGroup.vue";
import FormPretest from "@/components/FormPretest.vue";
import {isInteger, isNumeric, isRequired, isUrl, limitCharacter, limitValue} from "@/lib/ValidationHelper.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import {
  APPLY_CONFIRMATION_LABEL,
  COMPLETE_BIODATA_LABEL,
  CONFIRMATION_TITLE_LABEL,
} from "@/constants/label.js";
import useLoader from "@/plugins/loader/index.js";
import AppModal from "@/components/AppModal.vue";
import dayjs from "@/lib/DateHelper.js";
import BiodataForm from "@/components/BiodataForm.vue";
import {helpers, integer, requiredIf} from "@vuelidate/validators";
import {INTEGER_VALIDATE_LABEL} from "@/constants/rules-label.js";

const modal = ref(false)
const loader = useLoader()
const confirm = useConfirmation()
const notify = useNotify();
const route = useRoute();
const router = useRouter();
const jobstate = storeJobCode();
const authstate = useAuthStore();

const isAlreadyCompleted = ref(false);
const isAlreadyApply = ref(false);
const isCurrentShortlist = ref(true);
const state = ref([]);
const bannerShow = ref(true)

const information = ref([]);

const expanded = ref(true)
const form = ref([])

const latest_state = ref({});
const getColorStep = (state) => {
  let color = 'grey';
  if (getLatestStateCandidate.value.job_state_id === state.job_state_id)
    color = 'blue';
  if (state.is_audited)
    color = 'green'
  return color
}

const getLatestStateCandidate = computed(() => {
  const countAudited = state.value.filter(item => item.is_audited).length;
  if (countAudited >= state.value.length) {
    return state.value[countAudited - 1];
  }
  return state.value[countAudited];
});

const isReadOnly = computed(() => {
  return ((isAlreadyApply.value) || !isAlreadyCompleted.value || !isJobOpened.value)
})

const isJobOpened = computed(() => {
  return information.value.is_active === "t";
})

const formAccount = ref({
  email:authstate.email,
})

const biodataForm = ref({
  name: authstate.user,
  gender: null,
  religion: null,
  birth_date : null,
  city: null,
  phone_no: null,
  education: null,
  university : null,
  university_other: null,
  major: null,
  major_other: null,
  grade : null,
  graduation_year : null,
  experience_level: null,
  skills : null,
  cv_url : null
})

const rulesBiodata = computed(() => ({
  name: {
    ...isRequired('Nama'),
    ...limitCharacter('Nama',3)
  },
  gender: isRequired('Jenis kelamin'),
  religion: isRequired('Agama'),
  birth_date: isRequired('Tanggal lahir'),
  city: isRequired('Alamat domisili'),
  phone_no : {
    integer: helpers.withMessage(INTEGER_VALIDATE_LABEL, integer),
    ...isRequired('Nomor telepon'),
    ...limitCharacter('Nomor telepon', 10, 13)
  },
  education: isRequired('Jenjang pendidikan'),
  university: isRequired('Nama institusi'),
  university_other: {
    requiredIf: helpers.withMessage('Masukkan nama institusi jika tidak ada pilihan', requiredIf(biodataForm.value.university === 'Lainnya'))
  },
  major: isRequired('Jurusan'),
  major_other : {
    requiredIf: helpers.withMessage('Masukkan nama jurusan jika tidak ada pilihan', requiredIf(biodataForm.value.major === 'Lainnya'))
  },
  grade:  {...isRequired('Nilai Akhir'), ...isNumeric('IPK'), ...limitValue(0)},
  graduation_year:  {
    ...isRequired('Tahun lulus'),
    ...isInteger(),
    ...limitCharacter('Tahun lulus', 4, 4)
  },
  cv_url:  {
    ...isRequired('CV'),
    ...isUrl()},
  skills: isRequired('Keahlian'),
  experience_level: isRequired('Pengalaman')
}))

const formSalary = ref({
  expected_salary: null
})

const refetchBiodata = () => {
  loader.show()
  getJobByCode(jobstate.code)
      .then((res) => {
        const data = res.applicant
        biodataForm.value = {
          ...data,
          //birth_date: dayjs(res.birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          skills: data.skills.split(','),
          university: data.mstuniversity ? {value: data.mstuniversity.code, label: data.mstuniversity.name} : data.university,
          major: data.mstmajor ? {value: data.mstmajor.code, label: data.mstmajor.name} : data.major
        }
      })
      .catch((err) => {
        notify.show({message: err.message, type: 'error'})
      }).finally(() => {
    loader.hide()
    modal.value = false
  });
}

const redirectBack = () => {
  resetJobCode()
  router.push({
    name: 'JobCode'
  })
}

const $vuelidateBiodata = useVuelidate(rulesBiodata, biodataForm);

const resetJobCode = async () => {
  jobstate.setState(null);
  return await router.push({name: 'JobCode'})
}

const doView = () => {
  biodataForm.value = {
    ...biodataForm.value,
    birth_date: dayjs(biodataForm.value.birth_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
  }
  return modal.value = true
}

const doAdd = async () => {
  const validateBiodata = await $vuelidateBiodata.value.$validate();
  if(validateBiodata){
    confirm
        .setTitle(CONFIRMATION_TITLE_LABEL)
        .setMessage(COMPLETE_BIODATA_LABEL)
        .setOnConfirm(() => {
          loader.show()
          const data = {
            ...formAccount.value,
            ...biodataForm.value,
            birth_date: dayjs(biodataForm.value.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            skills: biodataForm.value.skills.slice(0, biodataForm.value.skills.length).join(',')
          }
          postApplicants(data).then((response) => {
            if (response) {
              notify.show({
                message: MESSAGE_SUCCESS_CREATED,
                type: 'success'
              })
            }
            isAlreadyCompleted.value = true
          }).catch((err) => {
            notify.show({message: err.message, type: 'error'})
          }).finally(() => {
            loader.hide()
            refetchBiodata()
          })
        }).show()
  }
}

const doEdit = async () => {
  const validBiodata = await $vuelidateBiodata.value.$validate();
  if (validBiodata) {
    confirm
        .setTitle(CONFIRMATION_TITLE_LABEL)
        .setMessage(APPLY_CONFIRMATION_LABEL)
        .setOnConfirm(() => {
          loader.show()
          const data = {
            ...biodataForm.value,
            university: biodataForm.value.university.value ?? biodataForm.value.university,
            major: biodataForm.value.major.value ?? biodataForm.value.major,
            birth_date: dayjs(biodataForm.value.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            skills: biodataForm.value.skills.slice(0, biodataForm.value.skills.length).join(','),
          }
          postApplicants(data).then((response) => {
            if (response) {
              notify.show({
                message: MESSAGE_SUCCESS_UPDATED,
                type: 'success'
              })
            }
            modal.value = false
          }).catch((err) => {
            notify.show({message: err.message, type: 'error'})
          }).finally(() => {
            loader.hide()
            refetchBiodata()
          })
        }).show()
  }
}

const doApply = async () => {
  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage(APPLY_CONFIRMATION_LABEL)
      .setOnConfirm(() => {
        loader.show()
        const data = {
          expected_salary: formSalary.value.expected_salary
        }
        postJobApply(data, jobstate.code, biodataForm.value.id)
            .then((response) => {
              if(response) {
                notify.show({
                  message: MESSAGE_SUCCESS_CREATED,
                  type: 'success'
                })
                fetch()
              }
            }).catch((err) => {
          notify.show({message: err.message, type: 'error'})
        }).finally(() => {
          loader.hide()
        });
      }).show()
}

const fetch = async () => {
  const jobCode = jobstate.code;
  information.value = await getJobByCode(jobCode).then((res) =>{
    return res
  });
  latest_state.value = information.value.state_flows.find(item => Number(item.id) === Number(information.value.current_state_id));
  const applicant = information.value.applicant
  if (applicant.id) {
    isAlreadyCompleted.value = true
    biodataForm.value = {
      ...applicant,
      skills: applicant.skills.split(','),
      university: applicant.mstuniversity ? {
        value:  applicant.mstuniversity.code,
        label: applicant.mstuniversity.name} : applicant.university,
      major: applicant.mstmajor ? {
        value: applicant.mstmajor.code,
        label: applicant.mstmajor.name} : applicant.major
    }
    const data_state = information.value.state_flows;
    let all_state = [];
    if (applicant.applicant_job && applicant.id === applicant.applicant_job.applicant_id) {
      all_state = JSON.parse(applicant.applicant_job.step_log).sort((a,b) => a.state_id - b.state_id)
    }
    for (let i=0; i<all_state.length; i++) {
      state.value[i] = all_state[i]
      for (let j=0; j<data_state.length; j++){
        if (data_state[j].state_id === all_state[i].state_id){
          state.value[i].deskripsi = data_state[j].mststate.description
          state.value[i].nama = data_state[j].mststate.name
        }
      }
    }
    if (applicant.applicant_job && applicant.id === applicant.applicant_job.applicant_id) {
      isAlreadyApply.value = true
      form.value = {
        ...applicant,
      };
      formSalary.value = {
        expected_salary:  Number(applicant.applicant_job.expected_salary)
      }
    }
  }
}

onMounted(async () => {
  if (!jobstate.code) {
    await resetJobCode()
  }
  else {
    await fetch();
  }
})


</script>

<template>
  <AppPage :title="isReadOnly ? 'Status Lamaran' : 'Formulir Lamaran'">
    <template v-slot:default>
      <div class="">

        <div class="q-ma-md">
          <q-banner
              rounded inline-actions
              :class="isAlreadyApply ? 'bg-green' : (isAlreadyCompleted && isJobOpened ? 'bg-yellow-8' : 'bg-red')"
              class="text-white"
              v-if="bannerShow"
              dense
          >
            {{isAlreadyApply ? 'Anda sudah melamar posisi ini' : (!isAlreadyCompleted ? 'Lengkapi biodata terlebih dahulu' : (isJobOpened ? 'Masukkan ekspektasi gaji untuk melamar posisi ini' : 'Pendaftaran lowongan ini sudah ditutup'))}}
            <template v-slot:avatar>
              <q-icon :name="isAlreadyApply ? 'check_circle' : (isAlreadyCompleted ? 'warning' : 'error')" color="white" size="sm" />
            </template>
            <template v-slot:action>
              <q-btn flat color="white" icon="close" size="sm" @click="bannerShow = false"/>
            </template>
          </q-banner>
        </div>

        <q-card flat bordered class="q-ma-md">
          <q-expansion-item
              icon="assignment"
              label="Detil Lowongan Kerja"
              caption="Informasi mengenai Lowongan Kerja yang Anda lamar"
              class="q-ma-sm"
              header-class="text-h6 text-bold"
          >
            <JobVacancyInformation :data="information"
                                   :use-copy-clipboard="false" :is-pelamar="true" :expected_salary="formSalary.expected_salary"/>
          </q-expansion-item>
        </q-card>

        <q-card flat bordered class="q-ma-md" v-if="information.hasformquestion && !information.isformanswered && isJobOpened">
          <div class="q-ma-md" v-show="!information.isformanswered">
            <q-icon name="warning" size="md" color="orange" class="blinking"/>
            <i class="text-red text-bold" >**Pada tahap ini ada beberapa pertanyaan/test yang harus diisi. Silakan isi dengan jujur dan sebaik mungkin**</i>
          </div>
          <q-expansion-item
              icon="assignment"
              :label="'Pengisian ' + latest_state.mststate.name"
              :caption="!information.isformanswered ? 'Silakan lengkapi form berikut' : 'Anda sudah mengisi formulir ini.'"
              class="q-ma-sm"
              header-class="text-h6 text-bold"
          >
            <!-- temporary value for test image-->
            <FormPretest
                :job_state_id="Number(information.current_state_id)"
                :has-form="information.hasformquestion"
                :is-answered="information.isformanswered"
                :applicant_id="Number(information.applicant.id)"
            >
            </FormPretest>
          </q-expansion-item>
        </q-card>

        <q-card flat bordered class="q-ma-md">
          <q-expansion-item
              v-model="expanded"
              icon="perm_identity"
              label="Biodata"
              :caption="'Informasi mengenai data umum dan latar pendidikan'"
              class="q-ma-sm"
              header-class="text-h6 text-bold"
          >
            <q-card flat>
              <BiodataForm :form="biodataForm" :is-read-only="isAlreadyCompleted" @update:biodata="doAdd"></BiodataForm>
              <div class="q-pa-md">
                <div class="row">
                  <div class="col-12" align="right">
                    <QEditButtonGroup v-if="isAlreadyCompleted" label="UBAH" size="md" @click="doView" /></div>
                </div>
              </div>
            </q-card>
          </q-expansion-item>
        </q-card>


        <q-card flat bordered class="q-ma-md" v-show="!isReadOnly">
          <q-form class="q-gutter-sm">
            <q-card-section class="q-mt-md">
              <div class="row">
                <div class="col-12">
                  <label class="q-my-sm">Ekspektasi Gaji Anda<span class="text-red">*</span></label>
                  <VCurrencyInput
                      v-model="formSalary.expected_salary"
                      id="applicant-expected-salary"
                      :readonly="isReadOnly"
                  />
                </div>
              </div>
            </q-card-section>

            <q-card-section class="q-pa-sm">
              <div class="row q-pa-md">
                <div class="col-12">
                  <q-btn
                      color="primary"
                      @click="doApply"
                      label="Apply Lamaran"
                      class="float-right"/>
                </div>
              </div>
            </q-card-section>
          </q-form>
        </q-card>

        <q-card flat bordered class="q-ma-md" v-if="state.length > 0">
          <q-card-section class="text-h6 q-ma-sm">Timeline Tahapan Anda</q-card-section>

          <div class="q-px-lg q-py-md q-ma-sm">
            <q-timeline color="grey" layout="dense">
              <q-timeline-entry
                  v-for="item in state"
                  :color="getColorStep(item)"
                  :icon="item.is_pass ? 'done' : 'assignment'"
                  :title="item.nama"
              >
              </q-timeline-entry>
            </q-timeline>
          </div>
        </q-card>
      </div>
      <AppModal
          width="700em"
          title="Edit Biodata Diri"
          v-model:modal="modal"
          persistent
          @hide="refetchBiodata"
      >
        <template v-slot:content>
          <BiodataForm :form="biodataForm" :is-read-only="false" @update:biodata="doEdit"></BiodataForm>
        </template>
      </AppModal>
    </template>
  </AppPage>
</template>
