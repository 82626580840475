<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {
  getMasterQuestion, getQuestionBankDetail, saveQuestionBank
} from "@/lib/api.js";
import AppPage from "@/components/AppPage.vue";
import useNotify from "@/plugins/notify/index.js";
import {useRoute, useRouter} from "vue-router";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import VInput from "@/components/input/VInput.vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import useLoader from "@/plugins/loader/index.js";
import {parseNestedJson} from "@/lib/Helper.js";
import PreviewQuestions from "@/components/PreviewQuestions.vue";

const notify = useNotify();
const route = useRoute();
const router = useRouter();
const confirm = useConfirmation()
const loader = useLoader();

const modal = ref(false);
const activeIndex = ref(null);
const action = ref(route?.params?.action || 'create');
const mode = ref('create');
const isReadOnly = computed(() => {
  return action.value === 'view'
})
const pageTitle = computed(() => {
  switch (action.value) {
    case 'create':
      return 'Buat Form Pertanyaan';
    case 'update':
      return 'Ubah Form Pertanyaan';
    case 'view':
      return 'Detil Form Pertanyaan';
    case 'edit':
      return 'Edit Form Pertanyaan';
  }
  return '';
})

const redirectBack = () => {
  router.push({
    name: 'questionMasterList'
  })
}

const columns = computed(() => [
  {
    label: 'No',
    name: 'no',
    field: 'no',
    align: 'center'
  },
  {
    label: 'Judul',
    name: 'title',
    field: 'title',
    align: 'left'
  },
  {
    label: 'Pertanyaan',
    name: 'question',
    field: 'question',
    align: 'left'
  },
  {
    label: 'Tipe',
    name: 'type',
    field: 'type',
    align: 'center',
    headerStyle: 'width: 20%'
  },
  {
    label: 'Daftar Opsi',
    name: 'options',
    field: 'options',
    align: 'left'
  },
  {
    label: 'Wajib diisi?',
    name: 'is_required',
    field: 'is_required',
    align: 'center'
  },
  {
    label: 'Aksi',
    name: 'action',
    field: 'action',
    align: 'center',
    headerStyle: 'width: 20%'
  }
])

const questionBankOptions = ref([]);
const isRequired = ref(false)
const questionPreview = ref('')

const dataTemp = ref([]);
const selectedQuestionBank = ref({
  label: null,
  value: {
    title: null,
    question: null,
    type: null,
    category: true,
    options: [],
    is_required: false
  }
});

const form = ref({
  name: null,
  description: null,
  data: null
});


const rules = {
  name: { required },
  description: { required }
}

const $vuelidate = useVuelidate(rules, computed(() => form.value));

// HANDLING ALL STRUCTURE DATA FORM HERE
const resetSelectedQuestionBank = () => {
  selectedQuestionBank.value = null;
}

const handleModal = () => {
  resetSelectedQuestionBank();
  mode.value = 'create';
  modal.value = !modal.value;
  activeIndex.value = null;
  questionPreview.value = null;
}

const showPreview = ref(false);
const handlePreview = () => {
  showPreview.value = true;
}

const addQuestionBank = () => {
  if (dataTemp.value.find(item => Number(item.id) === Number(selectedQuestionBank.value.value.id))) {
    notify.show({message: 'Pertanyaan ini sudah anda pilih', type: 'warning', timeout: 2000})
    return;
  }
  const temp = {
    id: selectedQuestionBank.value.value.id,
    title: selectedQuestionBank.value.value.title,
    question: selectedQuestionBank.value.value.question,
    type: selectedQuestionBank.value.value.type,
    options: JSON.parse(selectedQuestionBank.value.value.options),
    is_required: isRequired.value,
    display_order: dataTemp.value.length + 1,
    my_answer: null
  }
  dataTemp.value.push(temp);
  modal.value = false;
}

const setQestionPreview = () => {
  questionPreview.value = selectedQuestionBank.value.value.question;
}

const doDeleteTypeQuestion = (index) => {
  dataTemp.value.splice(index, 1);
}

const doSave = async () => {
  const valid = await $vuelidate.value.$validate();
  if (!valid) return;

  if (dataTemp.value.length <= 0) {
    notify.show({message: 'Pertanyaan tidak boleh kosong', type: 'warning', timeout: 3000})
    return false;
  }

  const params = {
    ...form.value,
    data: dataTemp.value,
    id: form.value?.id || null
  };

  params.question_ids = params.data.map((item, index ) => {
    return { id: Number(item.id), is_required: item.is_required, display_order: index + 1 };
  })

  let message;
  message = action.value === 'create' ? 'Data berhasil disimpan.' : 'Data berhasil diubah';

  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menyimpan data ini?')
      .setOnConfirm(() => {
        loader.show();
        saveQuestionBank(params)
            .then(res => {
              notify.show({message, type: 'success'});
              redirectBack();
            })
            .catch(err => { notify.show({message: err.message, type: 'error'}) })
            .finally(() => { loader.hide() })
      }).setOnCancel(() => { return false; }).show();
}

const initQuestionBankOptions = async () => {
  getMasterQuestion({})
      .then(res => {
        questionBankOptions.value = res.response.map(item => {
          return {
            label: item.title,
            value: item
          }
        })
      });
}

onMounted(async () => {
  await initQuestionBankOptions();
  if (action.value !== 'create') {
    loader.show();
    try {
      const promise = await getQuestionBankDetail(route?.params?.id);
      form.value = promise.response;
      dataTemp.value = parseNestedJson(form.value.data);
    } catch (err) {
      console.error(err);
    } finally {
      loader.hide();
    }
  }
});

</script>

<template>
  <AppPage :title="pageTitle">
    <template v-slot:default>
      <q-card flat>
        <q-card-section>
          <div class="q-gutter-y-lg q-mt-md">
            <div class="row">
              <div class="col-12 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-bold">Nama Kumpulan Soal<span class="text-red">*</span></label>
                <VInput
                    for="name-field-id"
                    v-model="form.name"
                    @blur="$vuelidate.name.$touch"
                    :errors="$vuelidate.name.$errors"
                    error-message="Wajib diisi"
                    :readonly="isReadOnly"
                ></VInput>
              </div>
            </div>
            <div class="row">
              <div class="col-12 q-pl-sm q-gutter-y-sm">
                <label class="q-my-sm text-label">Deskripsi<span class="text-red">*</span></label>
                <VInput
                    type="textarea"
                    for="description-field-id"
                    v-model="form.description"
                    @blur="$vuelidate.description.$touch"
                    :errors="$vuelidate.description.$errors"
                    error-message="Wajib diisi"
                    :readonly="isReadOnly"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <q-btn class="q-ma-sm" label="Tambah" icon="add" color="blue" @click="handleModal"></q-btn>
                <q-btn class="q-ma-sm" label="Preview" icon="preview" color="orange" @click="handlePreview"></q-btn>
              </div>
            </div>
            <div class="row">
              <div class="col-12 q-pl-sm q-gutter-y-md">
                <label class="text-h6 text-bold">Tabel daftar pertanyaan</label>
                <q-table class="q-table" table-header-class="text-bold" :columns="columns" :rows="dataTemp" no-data-label="Tidak ada data">
                  <template v-slot:body-cell-no="props">
                    <q-td :props="props">
                      {{ props.rowIndex + 1 }}
                    </q-td>
                  </template>
                  <template v-slot:body-cell-options="props">
                    <q-td :props="props">
                      <div v-for="(item, index) in props.row.options" :key="'td-option-'+index" class="q-gutter-y-xs">
                        {{ index + 1 }}. {{ item.label }}
                      </div>
                    </q-td>
                  </template>
                  <template v-slot:body-cell-is_required="props">
                    <q-td class="text-center">
                      <q-toggle v-model="props.row.is_required" :val="true" :label="props.row.is_required ? 'Wajib' : 'Tidak Wajib'"></q-toggle>
                    </q-td>
                  </template>
                  <template v-slot:body-cell-action="props">
                    <q-td class="q-gutter-x-sm" :props="props">
                      <q-btn outline color="red" icon="delete" @click="doDeleteTypeQuestion(props.rowIndex)"></q-btn>
                    </q-td>
                  </template>
                </q-table>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn label="Kembali" icon="chevron_left" color="grey" @click="redirectBack"></q-btn>
          <q-btn label="Simpan" color="green" @click="doSave"></q-btn>
        </q-card-actions>
      </q-card>

      <PreviewQuestions :forms="dataTemp" :show="showPreview" @update:show="val => showPreview = val"></PreviewQuestions>

      <q-dialog v-model="modal" persistent>
        <q-card class="w-100">
          <q-card-section>
            <slot name="title">
              <p class="text-h6 q-mb-none text-bold">{{ mode === 'create' ? 'Tambah' : 'Ubah' }} Pertanyaan</p>
            </slot>
          </q-card-section>
          <q-card-section class="q-gutter-y-md">
            <q-select
                outlined
                label="Pilih Soal Pertanyaan"
                for="select-question-bank-id"
                v-model="selectedQuestionBank"
                :options="questionBankOptions"
                use-input
                input-debounce="300"
                autocomplete="off"
                @update:model-value="setQestionPreview"
            >
              <template v-slot:no-option>
                <div class="q-ma-md">Tidak ada hasil</div>
              </template>
            </q-select>
            <q-input label="Detil" type="textarea" readonly outlined v-model="questionPreview"></q-input>
            <q-toggle v-model="isRequired" :val="true" :label="isRequired ? 'Wajib' : 'Tidak Wajib'"></q-toggle>

          </q-card-section>
          <q-card-actions align="right">
            <q-btn label="Simpan" icon="add" color="green" @click="addQuestionBank"></q-btn>
            <q-btn label="Batal" color="grey" @click="handleModal"></q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </template>
  </AppPage>
</template>

<style scoped>
.scroll { overflow: hidden; }
</style>