<script setup>
import {getFormPretest, saveFormQuestionApplicant} from "@/lib/api.js";
import {computed, onMounted, ref, watch} from "vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import useLoader from "@/plugins/loader/index.js";
import useNotify from "@/plugins/notify/index.js";

const notify = useNotify();

const props = defineProps({
  job_state_id: {
    type: Number,
    required: true
  },
  hasForm: {
    type: Boolean,
    default: false
  },
  isAnswered: {
    type: Boolean,
    default: false
  },
  applicant_id: {
    type: Number,
    required: true
  }
})
const localIsAnswered = ref(props.isAnswered);
const loader = useLoader()
const confirm = useConfirmation()
const editorRef = ref(null)
const form = ref(null);
const forms = ref([]);
const images = ref([
  {
    path: 'inquiry/dev/array-loop.png'
  },
  {
    path: 'inquiry/dev/file-reader.png'
  },
  {
    path: 'inquiry/dev/function-calls.png'
  },
  {
    path: 'inquiry/dev/function-triangle.png'
  },
  {
    path: 'inquiry/dev/loop.png'
  },
  {
    path: 'inquiry/dev/story.png'
  },
  {
    path: 'inquiry/dev/tree.png'
  },
  {
    path: 'inquiry/dev/recursion.png'
  }
])

const fetch = (job_state_id) => {
  getFormPretest({job_state_id, hasanswered: props.isAnswered, applicant_id: props.applicant_id})
      .then(res => {
        form.value = res;
        forms.value = JSON.parse(res.data);
        forms.value.forEach(item => {
          if (!item.my_answer) {
            item.my_answer = '';
            if (item.type === 'MULTIPLESELECT' || (item.type === 'CHECKBOX' && item.options.length > 1))
              item.my_answer = [];
          }
        });
        forms.value.sort((a, b) => a.display_order - b.display_order);
      }).catch(() => {
    form.value = null
  });
}
const handleTab = (e) => {
  if (e.key === 'Tab') {
    e.preventDefault();

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const tabNode = document.createTextNode("\t");

    range.insertNode(tabNode);

    // Move the caret after the inserted tab character
    range.setStartAfter(tabNode);
    range.setEndAfter(tabNode);

    selection.removeAllRanges();
    selection.addRange(range);
  }
}

const mappingParams = () => {
  const params = {};
  forms.value.forEach(item => {
    item.options = item.options === "[]" ? null : item.options;
    if (typeof item.my_answer !== 'number' && typeof item.my_answer !== 'object' && item.my_answer !== null && item.my_answer !== "") {
      item.my_answer = item.my_answer.replace(/\\u[0-9A-Fa-f]{4}/g, '');
      item.my_answer = item.my_answer.replace(/&[^;]+;/g, '');
    }
  });

  params.data = forms.value;
  params.applicant_id = props.applicant_id;
  params.job_state_question_id =  form.value.id;

  return params
}

const doSave = () => {
  const params = mappingParams();
  let isFormValid = true;
  let display_order = '';

  params.data.some(item => {
    if ((item.my_answer == null || item.my_answer === "" || (Array.isArray(item.my_answer) && item.my_answer.length === 0)) && !item.is_required) {
      display_order = item.display_order;
      isFormValid = false;
      return false;
    }
  });

  if (!isFormValid) {
    notify.show({
      message: `Form tidak valid, No. ${display_order} belum terjawab. Silakan periksa kembali`,
      type: 'warning',
      timeout: 3000
    })
    return false;
  }

  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menyimpan form ini?')
      .setOnConfirm(() => {
        loader.show();
        saveFormQuestionApplicant(params).then(res => {
          localIsAnswered.value = true;
          notify.show({
            message: 'Terima kasih. Jawaban anda sudah berhasil disimpan. ',
            type: 'success',
            timeout: 3000
          })
        }).catch(e => {
          console.error(e);
        }).finally(() => { loader.hide() })
      })
      .setOnCancel(() => { return false; }).show();
}

onMounted(() => {
  if (props.job_state_id) {
    fetch(props.job_state_id);
  }
})

watch(() => props.job_state_id, (newId) => {
  if (newId) {
    fetch(props.job_state_id);
  }
});

watch(() => props.isAnswered, (newValue) => {
  if (newValue) {
    localIsAnswered.value = newValue;
  }
});

</script>

<template>
  <div v-if="form">
    <q-card v-for="(row, index) in forms" :key="`index-${index}`">
      <q-card-section class="q-gutter-y-sm">
        <div class="text-h6 q-pb-sm text-bold">
          {{index + 1}}. {{ row.type === 'IMAGE' ? row.title : row.question }}
        </div>
        <template v-if="row.type === 'FREETEXT'">
          <div>
            <q-input autogrow type="textarea" v-model="row.my_answer" bg-color="grey-2" :readonly="localIsAnswered"></q-input>
          </div>
        </template>
        <template v-if="row.type === 'RADIO'">
          <div class="row q-gutter-sm">
            <div v-for="(child, childIndex) in row.options" :key="`childIndex-radio-${childIndex}`">
              <q-radio :label="child.label" :val="child.id" v-model="row.my_answer" :disable="localIsAnswered"></q-radio>
            </div>
          </div>
        </template>
        <template v-if="row.type === 'CHECKBOX' && row.options.length > 1">
          <div class="row q-gutter-sm">
            <div v-for="(child, childIndex) in row.options" :key="`childIndex-multiple-${childIndex}`">
              <q-checkbox
                  v-model="row.my_answer"
                  :val="child.id"
                  :label="child.label"
                  :readonly="localIsAnswered"
                  :key="`multi-radio-${childIndex}`"
              >
              </q-checkbox>
            </div>
          </div>
        </template>
        <template v-if="row.type === 'CHECKBOX' && row.options.length <= 1">
          <div>
            <q-checkbox :label="row.options[0].label" :value="true" v-model="row.my_answer" :disable="localIsAnswered"></q-checkbox>
          </div>
        </template>
        <template v-if="row.type === 'MULTIPLESELECT'">
          <q-select
              v-model="row.my_answer"
              multiple
              :options="row.options"
              option-label="label"
              option-value="id"
              use-input
              clearable
              emit-value
              map-options
              use-chips
              :readonly="localIsAnswered"
          ></q-select>
        </template>
        <template v-if="row.type === 'IMAGE'">
          <div class="row border-solid-black-1">
            <q-card>
              <img :src="row.question" :alt="row.title" :key="`image-question-${index}`" class="w-100 q-ma-md">
            </q-card>
          </div>
          <div class="flex flex-column">
            <q-editor
                v-model="row.my_answer"
                type="textarea"
                :toolbar="[['bold', 'italic']]"
                content-class="textarea-answer"
                @keydown="handleTab"
                ref="editorRef"
                :readonly="localIsAnswered"
            />
          </div>
        </template>
      </q-card-section>
    </q-card>
    <q-card-actions align="right" class="q-pt-md" v-if="!localIsAnswered">
      <q-btn label="Simpan" size="md" icon="save" color="green" @click="doSave"></q-btn>
    </q-card-actions>
  </div>

  <div v-else>
    <q-card-section>
      Belum ada isian dari kandidat pada tahapan ini
    </q-card-section>
  </div>
</template>

<style scoped>
 .border-solid-black-1 {
   border: 1px solid black
 }
 .textarea-answer {
   font-family: "Courier New";
   font-size: 14px;
 }
</style>
