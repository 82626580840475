<script setup>
import {computed, ref, watch} from "vue";
import VInput from "@/components/input/VInput.vue";
import useConfirmation from "@/plugins/confirmation/index.js";
import useNotify from "@/plugins/notify/index.js";
import {saveMasterQuestion} from "@/lib/api.js";
import VCurrencyInput from "@/components/input/vCurrencyInput/VCurrencyInput.vue";

const confirm = useConfirmation();
const notify = useNotify();
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  },
  mode: {
    type: String,
    default: 'create'
  },
  form: {
    type: Object,
    default: {}
  }
});

const emits = defineEmits(['update:form', 'update:show-modal']);

const typeOption = ref([
  'FREETEXT', 'MULTIPLESELECT', 'CHECKBOX', 'RADIO'
]);

const categoryOption = ref(['UMUM', 'TEKNIS'])

const iconTypeOption = computed(() => {
  switch (formTypeQuestion.value.type) {
    case 'FREETEXT': return 'text_fields';
    case 'MULTIPLESELECT': return 'format_list_bulleted';
    case 'MULTIPLE': return 'format_list_bulleted';
    case 'CHECKBOX': return 'check_box';
    case 'RADIO': return 'radio_button_checked';
    default: return 'grid_view';
  }
});


const tempTypeOptions = ref([]);
let formTypeQuestion = ref({
  id: null,
  title: null,
  question: null,
  type: null,
  options: null,
  category: 'UMUM'
});

const doDeleteTypeOptions = (index) => {
  tempTypeOptions.value.splice(index, 1);
};

const addTypeOption = () => {
  tempTypeOptions.value.push({
    id: tempTypeOptions.value.length + 1,
    label: null
  })
  const ele = document.getElementById('div-options');
  ele.scrollTop = ele.scrollHeight;
};

const doResetOptions = () => {
  if (formTypeQuestion.type === 'FREETEXT') {
    tempTypeOptions.value.splice(0, tempTypeOptions.value.length);
  }
}

const doResetForm = () => {
  formTypeQuestion.value = {
    id: null,
    title: null,
    question: null,
    type: null,
    options: null,
    category: 'UMUM'
  }
}

const formRangeType = ref([
  { id: 1, label: 4000000 },
  { id: 2, label: 5000000 },
])

watch(() => [props.showDialog, props.form, props.mode], ([newShowDialogVal, newFormVal, newValMode]) => {
  if (newValMode !== 'create') {
    formTypeQuestion = ref(newFormVal);
    tempTypeOptions.value = formTypeQuestion.value.options;
    if (formTypeQuestion.value.type === 'RANGE') {
      formRangeType.value = formTypeQuestion.value.options;
    }
  } else {
    doResetForm()
  }
});


const closeModal = () => {
  emits('update:show-modal', false);
}

const doEmitUpdate = () => {
  emits('update:form', true);
}

const doSave = () => {
  if (formTypeQuestion.value.type === 'RANGE') {
    tempTypeOptions.value = formRangeType.value;
  }

  if ((tempTypeOptions.value.length <= 0) && !(formTypeQuestion.value.type === 'FREETEXT') && formTypeQuestion.value.category !== 'TEKNIS') {
    notify.show({message: 'Pertanyaan tidak boleh kosong', type: 'warning', timeout: 3000})
    return false;
  }
  const params = {
    ...formTypeQuestion.value,
    options: tempTypeOptions.value || null,
    id: formTypeQuestion.value.id || null
  }
  confirm
      .setTitle('Konfirmasi')
      .setMessage('Apakah anda yakin akan menyimpan data ini?')
      .setOnConfirm(() => {
        saveMasterQuestion(params)
            .then((res) => {
              notify.show({message: 'Data Berhasil disimpan', type: 'success', timeout: 3000})
            })
            .catch((err) => {
              notify.show({message: err.message ?? 'Terjadi kesalahan', type: 'erraor', timeout: 3000})
            })
            .finally(() => { closeModal(); doEmitUpdate(); });
      }).setOnCancel(() => { return false; }).show();
}

const checkValueIfTeknis = (e) => {
  formTypeQuestion.value.type = null;
  if (e === 'TEKNIS') {
    formTypeQuestion.value.type = 'IMAGE';
    tempTypeOptions.value.splice(0, tempTypeOptions.value.length);
  }
}

const doesInputOptions = computed(()  => {
  let doesInput = true;
  if (!formTypeQuestion.value.type)
    doesInput = false;

  if (formTypeQuestion.value.category === 'TEKNIS' || formTypeQuestion.value.type === 'FREETEXT')
    doesInput = false;

  return doesInput;
});

</script>

<template>
  <q-dialog ref="dialog" v-bind:model-value="showDialog" persistent>
    <q-card class="w-100">
      <q-card-section>
        <slot name="title">
          <p class="text-h6 q-mb-none text-bold">{{ mode === 'create' ? 'Tambah' : 'Ubah' }} Pertanyaan</p>
        </slot>
      </q-card-section>
      <q-card-section class="q-gutter-y-md">
        <VInput
            error-message="Wajib diisi"
            label="Judul"
            for="header-field-id"
            v-model="formTypeQuestion.title"
        />
        <VInput
            type="textarea"
            error-message="Wajib diisi"
            label="Pertanyaan"
            for="header-field-id"
            v-model="formTypeQuestion.question"
        />
        <q-select
            label="Category"
            for="type-question-category"
            v-model="formTypeQuestion.category"
            :options="categoryOption"
            outlined
            @update:model-value="checkValueIfTeknis"
        />
        <q-select
            ref="type_question"
            label="Pilih Tipe Pertanyaan"
            for="type-question-id"
            v-model="formTypeQuestion.type"
            :options="typeOption"
            outlined
            @update:model-value="doResetOptions"
            :readonly="formTypeQuestion.category === 'TEKNIS'"
        >
          <template v-slot:prepend>
            <q-icon :name="iconTypeOption" @click.stop.prevent />
          </template>
        </q-select>
        <div id="div-options" style="max-height: 30vh; overflow-y: scroll" v-if="formTypeQuestion.type !== 'FREETEXT' && formTypeQuestion.type !== null">
          <div class="row q-gutter-y-sm" v-for="(item, index) in tempTypeOptions">
            <div class="col-1 align-self-center">{{index + 1}}. </div>
            <div class="col-9">
              <q-input v-model="item.label" type="text" :label="'Opsi ' + (index + 1)"></q-input>
            </div>
            <div class="col-2 align-self-center text-center">
              <q-btn icon="delete" color="red" size="md" flat outline @click="doDeleteTypeOptions(index)"></q-btn>
            </div>
          </div>
        </div>
        <div :class="formTypeQuestion.type === 'RANGE' ? '' : 'row'" :style="formTypeQuestion.type === 'RANGE' ? '' : 'justify-content: end'" v-if="doesInputOptions">
          <div class="row q-gutter-x-md" v-if="formTypeQuestion.type === 'RANGE'">
            <div class="col">
              <VCurrencyInput hint="Minimum" v-model="formRangeType[0].label" outlined></VCurrencyInput>
            </div>
            <div class="col">
              <VCurrencyInput hint="Maksimum" v-model="formRangeType[1].label" outlined></VCurrencyInput>
            </div>
          </div>
          <q-btn class="float-right" color="green" v-else icon="add" outline @click="addTypeOption"></q-btn>
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn class="q-ma-sm" label="Batal" flat color="grey" @click="closeModal"></q-btn>
        <q-btn class="q-ma-sm" :label="mode === 'create' ? 'Simpan' : 'Simpan Perubahan'" icon="save" color="green" @click="doSave"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>

</style>