import dayjs from 'dayjs';
import 'dayjs/locale/id.js';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);
dayjs.locale("id");

export const columnsJobVacancy = [
    {
        label: 'Judul Pekerjaan',
        name: 'job_title',
        field: 'job_title',
        align: 'left'
    },
    {
        label: 'Posisi',
        name: 'position',
        field: 'position',
        align: 'left'
    },
    {
        label: 'Lokasi',
        name: 'location',
        field: 'location',
        align: 'left'
    },
    {
        label: 'Tipe Tempat Kerja',
        name: 'workplace_type',
        field: 'workplace_type',
        align: 'left'
    },
    {
        label: 'Tipe Pekerjaan',
        name: 'employment_type',
        field: 'employment_type',
        align: 'left'
    },
    {
        label: 'Gaji Min',
        name: 'min_salary',
        field: 'min_salary',
        align: 'left'
    },
    {
        label: 'Gaji Maks',
        name: 'max_salary',
        field: 'max_salary',
        align: 'left'
    },
    {
        label: 'Tanggal Mulai',
        name: 'start_at',
        field: 'start_at',
        align: 'left'
    },
    {
        label: 'Tanggal Berakhir',
        name: 'end_date',
        field: 'end_date',
        align: 'left'
    },
    {
        label: 'Tahapan',
        name: 'latest_state',
        field: 'latest_state',
        align: 'left'
    },
    {
        label: 'Aksi',
        name: 'action',
        field: 'action',
        align: 'center'
    }
];

export const columnsStateFlows = [
    {
        label: 'Nama',
        name: 'name',
        field: 'name',
        align: 'left'
    },
    {
        label: 'Deskripsi',
        name: 'description',
        field: 'description',
        align: 'left'
    }
];

export const columnsKomentar = [
    {
        label: 'Nama',
        name: 'name',
        field: 'name',
        align: 'left'
    },
    {
        label: 'Domisili',
        name: 'city',
        field: 'city',
        align: 'left'
    },
    {
        label: 'Tingkat Keahlian',
        name: 'experience_level',
        field: 'experience_level',
        align: 'left'
    }
];

export const dummiesRejectedApplicants = [
    {
        nama: 'Fulan'
    },
    {
        nama: 'fulan 3'
    }
]

export const columnsApplicantsJob = [
    {
        label: 'Nama',
        name: 'name',
        field: 'name',
        align: 'left',
        headerClass: 'sticky-col'
    },
    {
        label: 'Domisili',
        name: 'city',
        field: 'city',
        align: 'left'
    },
    {
        label: 'Tahun Kelulusan',
        name: 'graduation_year',
        field: 'graduation_year',
        align: 'left'
    },
    {
        label: 'Ekspektasi Gaji',
        name: 'expected_salary',
        field: 'expected_salary',
        align: 'right'
    },
    {
        label: 'Tingkat Keahlian',
        name: 'experience_level',
        field: 'experience_level',
        align: 'left'
    },
    {
        label: 'Jurusan',
        name: 'major_name',
        field: row => row.major_name ? row.major_name : row.major_other,
        align: 'left'
    },
    {
        label: 'CV',
        name: 'cv_url',
        field: 'cv_url',
        align: 'left'
    },
    {
        label: 'Status',
        name: 'status_recruitment',
        field: 'status_recruitment',
        align: 'center'
    },
    {
        label: 'Aksi',
        name: 'action',
        field: 'action',
        align: 'left',
        headerStyle: 'text-align: center'
    }
];

export const dummiesApplicantsJob = [
    {
        name: 'Ahmad Fariz',
        description: 'Lulusan baru dengan gelar S1 Informatika, memiliki pengalaman magang sebagai web developer selama 6 bulan.',
        status: 'Active'
    },
    {
        name: 'Lia Sutanto',
        description: 'Memiliki pengalaman kerja 2 tahun sebagai data analyst di startup lokal. Terampil menggunakan Python dan R.',
        status: 'Active'
    },
    {
        name: 'Rendra Maulana',
        description: 'Freelancer desain grafis dengan portofolio yang luas. Berpengalaman menggunakan Adobe Creative Suite.',
        status: 'Active'
    },
    {
        name: 'Dian Ratnasari',
        description: 'Lulusan S1 Akuntansi dengan nilai cumlaude, memiliki pengalaman magang di perusahaan akuntansi terkemuka.',
        status: 'Active'
    },
    {
        name: 'Bayu Anggara',
        description: 'Software developer dengan 3 tahun pengalaman kerja. Fokus pada pengembangan aplikasi mobile menggunakan Flutter.',
        status: 'Active'
    }
]

export const defaultComment = (row) => {
    return `Dear <b>${row.name ?? '{candidate_name}'}</b> <br><br>
Thank you for your interest in the Integration Engineer role at PT. Akhdani Reka Solusi. We appreciate the time and effort you invested in submitting your application and participating in the interview process at ${row.job_title ?? '{place}'}. <br><br>
After careful consideration, we regret to inform you that we have chosen to move forward with other candidates for this particular position. However, we were impressed with your qualifications and believe that your skills and experience align well with our organization. <br><br>
As a result, we would like to inform you that we have decided to include your CV in our talent pool for future opportunities that match your profile. This means that we may reach out to you in the event that a suitable position becomes available. <br><br>
We understand that this news may be disappointing, but we genuinely value the effort you put into your application. We encourage you to stay connected with us by keeping an eye on our career portal for updates on new job openings that align with your expertise. <br><br>
We appreciate your understanding and wish you the very best in your career endeavors. Thank you once again for considering PT. Akhdani Reka Solusi as a potential employer. <br><br>
Best regards,<br>
Khilmiyatuz Zuhriyah<br>
Human Capital <br>
<b>PT. Akhdani Reka Solusi</b>`
}

const getNextStep = (row, job) => {
    const currentStep = eval(job.latest_state.display_order) + eval(1);
    return job.state_flows.find(item => Number(item.display_order) === Number(currentStep));
}

export const defaultCommentPassed = (row, job) => {
    return `Yth. Kandidat ######## <br>
Di Tempat<br><br>
Terima kasih sudah tertarik apply ke perusahaan kami. Berdasarkan hasil ##### anda lolos ke tahap berikutnya yakni ##### tahap 1.<br>
Tes ini akan menguji kemampuan kamu dalam hal Coding dan analytical thinking. Tes ini dilaksanakan secara online dan sychronous. oleh karena itu, perserta harus menyiapkan device dan jaringan yang memadai.
Kegiatan ini dilaksanakan pada:<br><br>
Hari: ${dayjs().format("dddd, D MMMM YYYY")}<br>
Pukul : ##.00 - ##.00 WIB<br>
Link Test :  #######<br>
Note: Mohon datang tepat waktu karena tidak ada reschedule jadwal<br>
Terima kasih atas perhatiannya, mohon untuk melakukan konfirmasi pada nomor berikut wa.me/6281515844985<br><br>
Regards,<br>
Tim recruitment<br>
<b>PT. Akhdani Reka Solusi</b>`

}