<script setup>

import { ref } from "vue";

const editorRef = ref(null)
const emits = defineEmits(['update:show']);
const my_answer = ref("");
const props = defineProps({
  forms: Array,
  show: {
    type: Boolean,
    default: false
  }
});

const closeModal = () => {
  emits('update:show', false);
}

</script>

<template>
  <q-dialog v-bind:model-value="show" persistent>
    <div v-if="forms" style="width: 80vw; max-width: 80vw">
      <q-card class="q-pa-md">
        <q-card-section>
          <div class="flex justify-between">
            <slot name="title">
              <p class="text-h6 q-mb-none">Preview Pertanyaan</p>
            </slot>
            <q-btn
                flat
                v-close-popup
                round
                dense
                icon="close"
                @click="emits('update:show', false)"
            />
          </div>
        </q-card-section>
        <div v-for="(row, index) in forms" :key="`index-${index}`">
          <q-card-section class="q-gutter-y-sm">
            <div class="text-h6 q-pb-sm text-bold">
              {{index + 1}}. {{ row.type === 'IMAGE' ? row.title : row.question }}
            </div>
            <template v-if="row.type === 'FREETEXT'">
              <div>
                <q-input autogrow type="textarea" v-model="row.my_answer" bg-color="grey-2" :readonly="false"></q-input>
              </div>
            </template>
            <template v-if="row.type === 'RADIO'">
              <div class="row q-gutter-sm">
                <div v-for="(child, childIndex) in row.options" :key="`childIndex-radio-${childIndex}`">
                  <q-radio :label="child.label" :val="child.id" v-model="row.my_answer" :disable="false"></q-radio>
                </div>
              </div>
            </template>
            <template v-if="row.type === 'CHECKBOX' && row.options.length > 1">
              <div class="row q-gutter-sm">
                <div v-for="(child, childIndex) in row.options" :key="`childIndex-multiple-${childIndex}`">
                  <q-checkbox
                      v-model="row.my_answer"
                      :val="child.id"
                      :label="child.label"
                      :readonly="false"
                      :key="`multi-radio-${childIndex}`"
                  >
                  </q-checkbox>
                </div>
              </div>
            </template>
            <template v-if="row.type === 'CHECKBOX' && row.options.length <= 1">
              <div>
                <q-checkbox :label="row.options[0].label" :value="true" v-model="row.my_answer" :disable="false"></q-checkbox>
              </div>
            </template>
            <template v-if="row.type === 'MULTIPLESELECT'">
              <q-select
                  v-model="row.my_answer"
                  multiple
                  :options="row.options"
                  option-label="label"
                  option-value="id"
                  use-input
                  clearable
                  emit-value
                  map-options
                  use-chips
                  :readonly="false"
              ></q-select>
            </template>
            <template v-if="row.type === 'IMAGE'">
              <div class="row border-solid-black-1">
                <q-card>
                  <img :src="'/' + row.question" :alt="row.title" :key="`image-question-${index}`" class="w-100 q-ma-md">
                </q-card>
              </div>
              <div class="flex flex-column">
                <q-editor
                    v-model="my_answer"
                    type="textarea"
                    :toolbar="[['bold', 'italic']]"
                    content-class="textarea-answer"
                    ref="editorRef"
                    :readonly="false"
                />
              </div>
            </template>
          </q-card-section>
        </div>
        <q-card-actions align="right" class="q-pt-md">
          <q-btn label="Kembali" size="md"  color="grey" @click="closeModal"></q-btn>
        </q-card-actions>
      </q-card>
    </div>
  </q-dialog>
</template>

<style scoped>

</style>