<script setup>
import {ref, watch} from "vue";
import useAuthStore from "@/stores/auth.js";
import {CONFIRMATION_TITLE_LABEL} from "@/constants/label.js";
import useConfirmation from "@/plugins/confirmation/index.js";
import { deactivateJobVacancy } from "@/lib/api.js";
import useNotify from "@/plugins/notify/index.js";

const props = defineProps({
  data: Object,
  useCopyClipboard: {
    type: Boolean,
    default: true
  },
  title: String,
  isPelamar: {
    type: Boolean,
    default: false
  },
  expected_salary: null
})

const isCopied = ref(false);
const toggleActiveJob = ref(true);
const confirm = useConfirmation();
const notify = useNotify();

const copyJobCode = () => {
  navigator.clipboard.writeText(props.data.job_code)
  isCopied.value = true;
  setTimeout(() => {
    isCopied.value = false
  }, 2000)
}
const doDeactivateJob = (value) => {
  toggleActiveJob.value = !value;

  confirm
      .setTitle(CONFIRMATION_TITLE_LABEL)
      .setMessage('Apakah anda ingin menutup lowongan kerja ini?')
      .setOnConfirm(() => {
        deactivateJobVacancy(props.data.job_code, value)
            .then(res => {
              toggleActiveJob.value = value;
              notify.show({
                message: 'Status lowongan berhasil diubah',
                type: 'success',
                timeout: 2000
              });
            }).catch(err => {
                console.error(err);
                notify.show({
                  message: 'Status lowongan gagal diubah',
                  type: 'error',
                  timeout: 2000
                });
            })
      }).setOnCancel(() => { toggleActiveJob.value = !value; return false; })
      .show();
}
const authState = useAuthStore();

watch(() => props.data, (value, oldValue) => {
  toggleActiveJob.value = value.is_active === "t";
})

</script>
<template>
  <q-card flat class="q-mb-md q-pb-md">
    <q-card-section>
      <div class="flex justify-between q-mb-xl">
        <div class="text-h6  text-bold">{{title}}</div>
        <slot name="top-action"></slot>
      </div>
        <div class="q-gutter-y-md">
          <div class="row">
            <div class="col-2 text-bold">
              Nama Lowongan
            </div>
            <div class="col-9">
              {{ data.job_title }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Posisi
            </div>
            <div class="col-9">
              {{ data.mstposition?.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Lokasi
            </div>
            <div class="col-9">
              {{ data.location }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Jenis Tempat Kerja
            </div>
            <div class="col-9">
              {{ data.workplace_type }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Jenis Pekerjaan
            </div>
            <div class="col-9">
              {{ data.employment_type }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 text-bold">
              Kode Referensi Lowongan
            </div>
            <div class="col-9">
              {{ data.job_code }} <span v-if="useCopyClipboard"><q-btn :icon="!isCopied? 'content_copy' : 'done'" dense
                                                                       flat size="sm"
                                                                       @click="copyJobCode"></q-btn></span>
            </div>
          </div>
          <div class="row" style="margin-top:auto" v-if="!isPelamar">
            <div class="col-2 text-bold" style="align-content: center">
              Status Lowongan
            </div>
            <div class="col-9">
              <q-toggle @update:model-value="doDeactivateJob" v-model="toggleActiveJob" :left-label="true" :label="toggleActiveJob ? 'Dibuka' : 'Ditutup'" :color="toggleActiveJob ? 'green' : 'red'" :true-value="true" :false-value="false"></q-toggle>
            </div>
          </div>
          <div class="row" v-if="isPelamar">
            <div class="col-2 text-bold">
              Ekspektasi gaji Anda
            </div>
            <div class="col-9">
              {{ expected_salary ? expected_salary.toLocaleString('id') : 'anda belum melamar' }}
            </div>
          </div>
        </div>
    </q-card-section>
  </q-card>
  <q-card v-if="authState.isPelamar">
    <q-card-section>
      <div class="row">
        <div class="col-2 text-bold">
          Persyaratan
        </div>
        <div class="col-9">
          <p v-html="data.requirements"></p>
        </div>
      </div>
    </q-card-section>
    <q-card-section>
      <div class="row">
        <div class="col-2 text-bold">
          Tanggung Jawab
        </div>
        <div class="col-9">
          <p v-html="data.responsibilities"></p>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
<style scoped></style>
