import {del, get, post, put} from "./HttpHelper.js";

export const login = (params) => {
    return post({path: '/auth/login', params, isNeedAuthorization: false});
}

export const register = (params) => {
    return post({path: '/auth/register', params, isNeedAuthorization:false})
}

export const getJobVacancy = (params) => {
    return get({ path: '/job-vacancy/list' , params});
}

export const getDetailJobVacancy = (params) => {
    return get({path: `/job-vacancy/${params}`});
}
export const getApplicants = (params) => {
    return get({path: '/applicants/list', params, isNeedAuthorization: true});
}

export const postApplicants = (params) => {
    return post({path: '/applicants/', params, isNeedAuthorization: true});
}

export const postJobApply = (params,jobCode, applicantsId) => {
    return post({path: `/applicants/application/${jobCode}/${applicantsId}`,params, isNeedAuthorization: true})
}

export const getReferences = (params) => {
    return get({path: '/master/reference/list/' + params, isNeedAuthorization: false})
}

export const searchJobVacancy = (params) => {
    return get({path: '/job-vacancy/get', params});
}

export const postJobVacancy = (params) => {
    return post({path: '/job-vacancy/save', params});
}

export const updateJobVacancy = (id, params) => {
    return post({path: `/job-vacancy/${id}`, params});
}

export const deleteJobVacancy = (id) => {
    return post({path: `/job-vacancy/delete/${id}`});
}

export const getPosition = (params) => {
    let path = ''
    return get({path: '/master/position/get', params})
}

export const getRole = (params) => {
    let path = ''
    return get({path: '/role/list', params})
}

export const deletePosition = (id) => {
    return post({path: `/master/position/${id}`});
}

export const postPosition = (params) => {
    return post({path: '/master/position/save', params})
}

export const postSkill = (params) => {
    return post({path: '/master/skill/save', params})
}

export const UpdateSkill = (id, params) => {
    return post({path: `/master/skill/update/${id}`, params})
}

export const getDetailSkillsMaster = (params) => {
    return get({path: `/master/skill/${params}`});
}

export const putPosition = (id, params) => {
    return post({path: `/master/position/update/${id}`, params})
}

export const getPositionList = (params) => {
    return get({path: `/master/position/list`, params})
}

export const getMasterReferenceByType = (type) => {
    return get({ path: `/master/reference/list/${type}` })
}

export const getListState = (params) => {
    return get({path: '/master/state'})
}

export const getStateById = (id) => {
    return get({path: `/master/state/${id}`})
}

export const generateJobCode = (params) => {
    return get({ path:'/job-vacancy/generate-job-code' });
}

export const getJobByCode = (code) => {
    return get({path: `/job-vacancy/get-job-by-code/${code}`})
}

export const getByJob = (params) => {
    return get({ path: `applicants/get-by-job`, params })
}

export const postNextStep = (params, job_id, state_id, display_order) => {
    return post({path: `/job-vacancy/next-step/${job_id}/${state_id}/${display_order}`,params})
}

export const getApplicantsById = (id) => {
    return get({path: '/applicants/' + id});
}

export const deleteApplicantsById = (id) => {
    return post({path: `/applicants/delete/${id}`});
}

export const getInstitutions = () => {
    return get({path: '/master/institutions/getAll'})
}

export const getInstitutionsByName = (params) => {
    return get({path: '/master/institutions/get', params})
}

export const getMajor = (params) => {
    return get({path: '/master/major/get', params})
}

export const getMasterSkill = (params) => {
    return get({path: '/master/skill/list', params})
}

export const getSkills = (params) => {
    return get({path: '/master/skill/get', params})
}

export const deleteSkill = (id) => {
    return post({path: `/master/skill/${id}`});
}

export const getFormPretest = (params) => {
    return get({path: '/form_question/get', params})
}

export const saveFormQuestionApplicant = (params) => {
    return post({path: '/form_question/save-answer', params})
}

export const saveFormQuestion = (params) => {
    return post({path: '/form_question/save', params})
}

export const postAuditApplicant = (params) => {
    return post({path: '/applicants/scoring', params})
}
export const postAuditApplicantBulk = (params) => {
    return post({path: '/applicants/scoring-bulk', params})
}

export const getListFormQuestion = (params) => {
    return get({path: '/form_question/list', params})
}

export const getListMasterQuestion = (params) => {
    return get({path: '/master/question/list', params});
}

export const saveMasterQuestion = (params) => {
    return post({path: '/master/question/save', params});
}

export const deleteMasterQuestion = (id) => {
    return post({path: `/master/question/delete?id=${id}`})
}

export const getMasterQuestion = (params) => {
    return get({path: `/master/question/get`, params});
}

export const getQuestionBankList = (params) => {
    return get({path: `/question-bank/list`, params});
}

export const getQuestionBank = (params) => {
    return get({path: `/question-bank/get`, params});
}

export const saveQuestionBank = (params) => {
    return post({path: `/question-bank/save`, params});
}

export const getQuestionBankDetail = (id) => {
    return get({path: `/question-bank/detail?id=${id}`});
}

export const getListFormQuestionById = (id) => {
    return get({path: '/form_question/' + id});
}

export const getCountCandidateAudit = (params) => {
    return get({  path: '/job-vacancy/count-candidate-audit', params })
}

export const deactivateJobVacancy = (code, isactive) => {
    return post({path: `/job-vacancy/deactivate/${code}?isactive=${isactive}`});
}

export const verifyAccount = (token) => {
    return post({path: `/auth/confirmation?token=${token}`})
}

export const refreshAccessToken = (token) => {
    return get({path: `auth/refresh_token?token=${token}`})
}

export const duplicatJobVacancy = (id, params) => {
    return post({path: `/job-vacancy/duplicate?job_id=${id}`, params})
}

export const getListEmailLoggier = (params) => {
    return get({path: '/log/email/list', params});
}

export const sendEmail = (id) => {
    return post({path: `/log/email/send?id=${id}`});
}

export const updateAndSendEmail = (params) => {
    return post({path: '/log/email/update', params});
}

export const putRole = (id, params) => {
    return post({path: `/role/update`, params})
}

export const deleteRole = (id) => {
    return post({path: `/role/delete/${id}`});
}

export const getReferencesMaster = (params) => {
    return get({ path: '/master/reference/list' , params});
}

export const deleteReferencesMaster = (id) => {
    return post({path: `/master/reference/${id}`});
}

export const postReferencesMaster= (params) => {
    return post({path: `/master/reference/`, params});
}

export const updateReferencesMaster = (id, params) => {
    return post({path: `/master/reference/update/${id}`, params});
}

export const getDetailReferencesMaster = (params) => {
    return get({path: `/master/reference/${params}`});
}

export const getStateMaster = (params) => {
    return get({ path: '/master/state/list' , params});
}

export const getDetailStateMaster = (params) => {
    return get({path: `/master/state/${params}`});
}

export const postStateMaster= (params) => {
    return post({path: `/master/state/`, params});
}

export const deleteStateMaster = (id) => {
    return post({path: `/master/state/${id}`});
}

export const updateStateMaster = (id, params) => {
    return post({path: `/master/state/update/${id}`, params});
}
